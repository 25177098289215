import React, { createContext, useContext, useEffect, useState } from 'react';
import { ethers } from 'ethers';

import {contributionAbi} from '../abi';
import {toast} from "react-toastify";

const usdtAddress = process.env.REACT_APP_USTD_ADDRESS
const contributionContractAddress = process.env.REACT_APP_CONTRIBUTION_CONTRACT_ADDRESS
const correctNetworkId = process.env.REACT_APP_NETWORK_ID

// Create context
const EthereumContext = createContext(null);

export const useEthereum = () => useContext(EthereumContext);

export const EthereumProvider = ({ children }) => {
    const [provider, setProvider] = useState(null);
    const [signer, setSigner] = useState(null);
    const [contributionContract, setContributionContract] = useState(null);
    const [approveAndContributeLoading, setApproveAndContributeLoading] = useState(false);

    const usdtAbi = [
        "function approve(address spender, uint256 amount) external returns (bool)",
    ];

    useEffect(() => {
        async function initialize() {
            if (typeof window.ethereum !== 'undefined') {
                const provider = new ethers.providers.Web3Provider(window.ethereum, "any");

                const signer = provider.getSigner();
                const network = await provider.getNetwork();

                if (network.chainId !== parseInt(correctNetworkId)) {
                    toast.warning(`Please connect to the correct network. Expected network ID: ${correctNetworkId}`)
                    return;
                }

                const abi = contributionAbi;
                const contributionContract = new ethers.Contract(contributionContractAddress, abi, signer);

                window.ethereum.on('accountsChanged', (accounts) => {
                    if (accounts.length > 0) {
                        // console.log('Account changed to:', accounts[0]);
                        initialize();
                    } else {
                        toast.warning('Please connect an account.')
                    }
                });

                window.ethereum.on('chainChanged', (chainId) => {
                    window.location.reload();
                });

                setProvider(provider);
                setSigner(signer);
                setContributionContract(contributionContract);
            } else {
                toast.warning('Please install MetaMask!')
            }
        }

        initialize();
    }, []);

    const approveAndContribute = async (amount) => {
        if (!contributionContract || !signer) return;
        if(amount === '' || parseInt(amount) < 50 || parseInt(amount) > 10000){
            toast.warn('Amount must be between $50 and $10,000');
            return;
        }
        const usdtContract = new ethers.Contract(usdtAddress, usdtAbi, signer);
        const usdtAmount = ethers.utils.parseUnits(amount.toString(), 18);
        setApproveAndContributeLoading(true);
        try {
            const approveTx = await usdtContract.approve(contributionContract.address, usdtAmount);
            // console.log("Approval transaction sent: ", approveTx.hash);

            await approveTx.wait();
            // console.log("Approval transaction mined");

            const contributeTx = await contributionContract.contribute(usdtAmount);
            // console.log("Contribution transaction sent: ", contributeTx.hash);

            const receipt = await contributeTx.wait();

            toast.success('You have contributed successfully. Thank you for taking an interest in DAOPEOPLE!')
            // console.log("Contribution transaction mined: ", receipt);
        } catch (error) {


            toast.error(error.reason.split(':')[1] ?? 'Error while contributing. Please try again later.');

        } finally {
            setApproveAndContributeLoading(false);
        }

    };

    const claimTokens = async () => {
        if (!contributionContract) return;
        const tx = await contributionContract.claim();
        console.log("Claim transaction sent: ", tx.hash);

        const receipt = await tx.wait();
        console.log("Claim transaction mined: ", receipt);
    };

    const checkClaimingEnabled = async () => {
        if (!contributionContract) return false;
        return await contributionContract.claimingEnabled();
    };

    return (
        <EthereumContext.Provider value={{
            provider,
            signer,
            contributionContract,
            approveAndContribute,
            claimTokens,
            checkClaimingEnabled,
            approveAndContributeLoading
        }}>
            {children}
        </EthereumContext.Provider>
    );
};
