import style from './Select.module.css';

const Select = (props) => {
    return <div className={style.wrapper}>
        <div className={style.arrow}>
            <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#FFFFFF">
                <path d="M480-384 288-576h384L480-384Z"/>
            </svg>
        </div>
        <select className={style.select} {...props}>
            {props.children}
        </select>
    </div>
}

export default Select;