import Button from "../../components/button/Button";
import style from './Claim.module.css';

const Claim = () => {
    return <div className={style.wrapper}>
        <Button disabled>Claim</Button>
        <p style={{marginTop: '10px'}}>Coming soon...</p>
    </div>
}

export default Claim;