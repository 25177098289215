import styles from './Navigation.module.css';
import {NavLink} from "react-router-dom";
import Button from "../../components/button/Button";
import {useAccount, useConnect} from "wagmi";
import {useEffect} from "react";
import {toast} from "react-toastify";

const Navigation = () => {
    const { connect, connectors, error } =
        useConnect()

    const { address, isConnected } = useAccount()

    useEffect(() => {
        if(error){
            toast.error(error.message)
        }
    }, [error])


    const handleConnect =  () => {
        const connector = connectors.find((connector) => connector.type === 'injected')
        connect({connector})
    }

    return <nav className={styles.navigationWrapper}>
        <div className={styles.navItemsWrapper}>
            <div className={styles.navItem}>
                <NavLink
                    className={(props) => props.isActive ? styles.active : ''}
                    to={'/'}
                >
                    Home
                </NavLink>
            </div>
            {/*<div className={styles.navItem}>*/}
            {/*    <NavLink*/}
            {/*        className={(props) => props.isActive ? styles.active : ''}*/}
            {/*        to={'/contribute'}*/}
            {/*    >*/}
            {/*        Contribute*/}
            {/*    </NavLink>*/}
            {/*</div>*/}
            <div className={styles.navItem}>
                <NavLink
                    className={(props) => props.isActive ? styles.active : ''}
                    to={'/claim'}
                >
                    Claim
                </NavLink>
            </div> 
        </div>

        {!isConnected ? <Button style={{width: 'fit-content', padding: '10px 20px', minHeight: '40px'}}  onClick={handleConnect}>Connect Wallet</Button> : <Button style={{width: 'fit-content', padding: '10px 20px', minHeight: '40px'}}>Address: {address}</Button>}


    </nav>
}

export default Navigation;