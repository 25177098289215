import React from 'react';
import ReactDOM from 'react-dom/client';
import { WagmiProvider } from 'wagmi'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/home/Home";
import Contribute from "./pages/contribute/Contribute";
import Claim from "./pages/claim/Claim";

import {config} from "./config";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {EthereumProvider} from "./context/EthereumContext";
import {ToastContainer} from "react-toastify";

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <EthereumProvider>
          <WagmiProvider config={config}>
              <QueryClientProvider client={queryClient}>
                  <BrowserRouter>
                      <Routes>
                          <Route path={'/'} element={<App/>}>
                              <Route index element={<Home/>}/>
                              <Route path={'contribute'} element={<Contribute/>}/>
                              <Route path={'claim'} element={<Claim/>}/>
                          </Route>
                      </Routes>
                  </BrowserRouter>
              </QueryClientProvider>
          </WagmiProvider>
      </EthereumProvider>
      <ToastContainer
          position="top-center"
      />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
