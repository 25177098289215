import style from './App.module.css';
import Navigation from "./layout/navigation/Navigation";
import {Outlet} from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <section className={style.wrapper}>
        <div className={style.videoBackgroundContainer}>
            <video autoPlay muted loop>
                <source src={'/assets/videos/background_video.mp4'} type={'video/mp4'} />
            </video>
        </div>

        <div className={style.backgroundOverlay}></div>
        <div className={style.container}>
            <Navigation/>
            <main>

                <Outlet/>
            </main>
        </div>

    </section>
  );
}

export default App;
