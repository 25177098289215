import style from './Contribute.module.css';
import Input from "../../components/input/Input";
import Button from "../../components/button/Button";
import {useState} from "react";

import {useEthereum} from "../../context/EthereumContext";
import Loading from "../../components/loading/Loading";

const Contribute = () => {
    const [contributeValue, setContributeValue] = useState('')

    const {approveAndContribute, approveAndContributeLoading} = useEthereum()

    const handleChange = (e) => {
        setContributeValue(e.target.value)
    }

    const handleSubmit = async () => {
        await approveAndContribute(contributeValue)
    }

    return <div className={style.wrapper}>
        <div className={style.row}>
            <Input
                type={'number'}
                min={50}
                max={10000}
                value={contributeValue}
                onChange={handleChange}
                placeholder={'Amount to contribute'}
            />
        </div>
        <div className={style.row}>
            <Button disabled={approveAndContributeLoading} onClick={handleSubmit}>
                {approveAndContributeLoading ?
                    <>
                        <Loading style={{marginRight: '10px'}}/>
                        Transaction in progress. Please wait...
                    </> :
                    'Pay with Metamask'}

            </Button>
        </div>
    </div>
}

export default Contribute;