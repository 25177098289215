import style from "./Home.module.css";
import {useState} from "react";
import Input from "../../components/input/Input";
import Select from "../../components/select/Select";
import Button from "../../components/button/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Home = () => {

    const [formData, setFormData] = useState({
        email: "",
        twitter: "",
        telegram: "",
        network: "",
        distributionAddress: '',
        potentialInvestment: '',
        usaAck: false,
        riskAck: false,
        kycAck: false,
        haveEvmAddress: false,
    });

    const [submiting, setSubmiting] = useState(false)

    const [error, setError] = useState(null);

    // eslint-disable-next-line no-restricted-globals
    const query = new URLSearchParams(location.search);

    const navigate = useNavigate();


    let referalCode = query.get('Partner_id') ?? 0;
    referalCode = parseInt(referalCode);

    const tokensToBeReceived = formData.potentialInvestment === '' ? 0 : formData.potentialInvestment * 7142.86;

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmiting(true)
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/presale/react', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({...formData, referalCode}),
            });

            const data = await response.json();

            if(!response.ok) {
                throw new Error(data.message);
            }

            toast.success('Registered successfully!')

            // eslint-disable-next-line react-hooks/rules-of-hooks

            navigate('/contribute', {replace: true});

        } catch (error) {

            setError(error.message);
            toast.error(error.message);
        } finally {
            setSubmiting(false)
        }


    }

    return <>
        <div className={style.column}>
            <div className={style.row}>
                <h2 className={style.headingTitle}>
                    Welcome to DAOPEOPLE fan club with contribution page
                </h2>
            </div>
            <div className={style.row}>
                <p className={style.description}>
                    Welcome to the DAOPEOPLE Fan Club KYC (Know Your Client) page.
                    You’re just a couple of steps away from entering the ecosystem of
                    Web3 Social Media. Stick with us as we obtain a little more information from you.
                    Current DPPL token price is $0.00014.
                </p>
            </div>

            <form onSubmit={handleSubmit}>
                <div className={style.row}>
                    <Input
                        type="email"
                        name="email"
                        id="email"
                        required
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="E-mail Address *"
                    />
                </div>
                <div className={style.row}>
                    <Input
                        type="text"
                        name="twitter"
                        id="twitter"
                        value={formData.twitter}
                        onChange={handleChange}
                        placeholder="Twitter Handle"
                    />
                </div>
                <div className={style.row}>
                    <Input
                        type="text"
                        name="telegram"
                        id="telegram"
                        value={formData.telegram}
                        onChange={handleChange}
                        placeholder="Telegram Handle"
                    />
                </div>
                <div className={style.row}>
                    <Select
                        name={'network'}
                        id={'network'}
                        required
                        value={formData.network}
                        onChange={handleChange}
                        placeholder={'Select Network'}
                    >
                        <option value="">Select Network *</option>
                        <option value="Ethereum">Ethereum</option>
                        <option value="BSC">BSC</option>
                    </Select>
                </div>
                <div className={style.row}>
                    <Input
                        type="text"
                        name="distributionAddress"
                        id="distributionAddress"
                        required
                        value={formData.distributionAddress}
                        onChange={handleChange}
                        placeholder="Contribution & Distribution address for DPPL tokens"
                    />
                </div>
                <div className={style.row}>
                    <small className={style.warning}>
                        ATTENTION: Contribution & Distribution address has to be Trust or MetaMask wallet
                        address otherwise your DPPL tokens might be not accessible after the distribution.
                    </small>
                </div>
                <div className={style.row}>
                    <Input
                        type="number"
                        name="potentialInvestment"
                        id="potentialInvestment"
                        required
                        value={formData.potentialInvestment}
                        onChange={handleChange}
                        placeholder="Potential Investment in USDT *"
                        min={50}
                        max={10000}
                    />
                </div>
                <div className={style.row}>
                    <p>Amount of tokens to be received: <strong>{tokensToBeReceived.toFixed(2)}</strong></p>
                    <small className={style.info}>min 50$ - max 10 000$</small>
                </div>
                <div className={style.row}>

                    <input
                        type="checkbox"
                        name="usaAck"
                        id="usaAck"
                        required
                        value={formData.usaAck}
                        onChange={(e) => {
                            handleChange({
                                target: {
                                    name: e.target.name,
                                    value: e.target.checked,
                                },
                            });
                        }}
                    />
                    <label htmlFor="usaAck" style={{wordWrap: 'break-word'}}>
                        I am not a citizen of the USA, or China (Mainland), as well as: Burundi, Bahamas, Botswana,
                        Congo (Dem. Rep.), Central African Republic, Cuba, Ethiopia, Ghana, Iraq, Iran, Turkey,
                        Cambodia,
                        Korea (North), Lebanon, Sri Lanka, Libya, Mali, Myanmar, Nicaragua, Panama, Pakistan, Sudan,
                        Somalia, South Sudan, Syrian Arab Republic, Tunisia, Venezuela, Yemen, Zimbabwe, Crimea
                        (Ukrainian Region). *
                    </label>
                </div>
                <div className={style.row}>

                    <input
                        type="checkbox"
                        name="riskAck"
                        id="riskAck"
                        required
                        value={formData.riskAck}
                        onChange={(e) => {
                            handleChange({
                                target: {
                                    name: e.target.name,
                                    value: e.target.checked,
                                },
                            });
                        }}
                    />
                    <label htmlFor="riskAck" style={{wordWrap: 'break-word'}}>
                        I understand the risks involved with participating in token pre sales and that all contribution
                        doesnt have any guarantees and may result in total losses. *
                    </label>
                </div>
                <div className={style.row}>

                    <input
                        type="checkbox"
                        name="kycAck"
                        id="kycAck"
                        required
                        value={formData.kycAck}
                        onChange={(e) => {
                            handleChange({
                                target: {
                                    name: e.target.name,
                                    value: e.target.checked,
                                },
                            });
                        }}
                    />
                    <label htmlFor="kycAck" style={{wordWrap: 'break-word'}}>
                        I am willing to verify my identity when needed with KYC provider. *
                    </label>
                </div>
                <div className={style.row}>

                    <input
                        type="checkbox"
                        name="haveEvmAddress"
                        id="haveEvmAddress"
                        required
                        value={formData.haveEvmAddress}
                        onChange={(e) => {
                            handleChange({
                                target: {
                                    name: e.target.name,
                                    value: e.target.checked,
                                },
                            });
                        }}
                    />
                    <label htmlFor="haveEvmAddress" style={{wordWrap: 'break-word'}}>
                        I understand that "Contribution & Distribution address for DPPL tokens" has to be Trust or
                        MetaMask
                        wallet address ERC20 or BEP20, for which I have control over the private keys. From same address
                        I need to send the funds for Contribution which will be provided in email, and to that same
                        address
                        DPPL tokens will be Distributed after the TGE. *
                    </label>
                </div>
                <Button disabled={submiting} type="submit">
                    Submit
                </Button>
            </form>

        </div>
        <div className={style.column}>
        <div className={style.row}>
                <img src="/assets/images/dptoken.png" alt="DPTOKEN"/>
            </div>
            <div className={style.row}>
                <img src="/assets/images/DAOPEOPLE.png" alt="DAOPEOPLE"/>
            </div>
        </div>
    </>
}

export default Home;